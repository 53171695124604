export const GET_QUESTIONS_START = 'GET_QUESTIONS_START';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_ERROR = 'GET_QUESTIONS_ERROR';

export const GET_PLACES_RANKING_START = 'GET_PLACES_RANKING_START';
export const GET_PLACES_RANKING_SUCCESS = 'GET_PLACES_RANKING_SUCCESS';
export const GET_PLACES_RANKING_ERROR = 'GET_PLACES_RANKING_ERROR';

export const GET_PLACE_START = 'GET_PLACE_START';
export const GET_PLACE_SUCCESS = 'GET_PLACE_SUCCESS';
export const GET_MARKERS_START = 'GET_MARKERS_START';
export const GET_MARKERS_SUCCESS = 'GET_MARKERS_SUCCESS';
export const GET_MORE_PLACE_INFO = 'GET_MORE_PLACE_INFO';
export const GET_PLACE_ERROR = 'GET_PLACE_ERROR';
export const POST_PLACE_START = 'POST_PLACE_START';
export const POST_PLACE_SUCCESS = 'POST_PLACE_SUCCESS';
export const POST_PLACE_ERROR = 'POST_PLACE_ERROR';
export const RESET_PLACE_STATE = 'RESET_PLACE_STATE';

export const GET_PLACE_EVALUATIONS_START = 'GET_PLACE_EVALUATIONS_START';
export const GET_PLACE_EVALUATIONS_SUCCESS = 'GET_PLACE_EVALUATIONS_SUCCESS';
export const GET_PLACE_EVALUATIONS_SUMS = 'GET_PLACE_EVALUATIONS_SUMS';
export const POST_PLACE_EVALUATIONS_START = 'POST_PLACE_EVALUATIONS_START';
export const POST_PLACE_EVALUATIONS_SUCCESS = 'POST_PLACE_EVALUATIONS_SUCCESS';
export const POST_PLACE_EVALUATIONS_ERROR = 'POST_PLACE_EVALUATIONS_ERROR';
export const DELETE_PLACE_EVALUATIONS_START = 'DELETE_PLACE_EVALUATIONS_START';
export const DELETE_PLACE_EVALUATIONS_SUCCESS =
  'DELETE_PLACE_EVALUATIONS_SUCCESS';
export const DELETE_PLACE_EVALUATIONS_ERROR = 'DELETE_PLACE_EVALUATIONS_ERROR';

export const SET_DIRECTIONS = 'SET_DIRECTIONS';
export const SET_ROUTE = 'SET_ROUTE';
export const RESET_ROUTES = 'RESET_ROUTES';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const LOGOUT = 'LOGOUT';

export const INCREASE_FONT_SIZE = 'INCREASE_FONT_SIZE';
export const DECREASE_FONT_SIZE = 'DECREASE_FONT_SIZE';
export const RESET_FONT_SIZE = 'RESET_FONT_SIZE';
export const SET_FONT = 'SET_FONT';
export const SET_BACKGROUND_COLOR = 'SET_BACKGROUND_COLOR';
export const TOGGLE_COLORS = 'TOGGLE_COLORS';
export const SET_UNDERLINE = 'SET_UNDERLINE';
export const SET_HIGHLIGHT = 'SET_HIGHLIGHT';
export const REMOVE_ANIMATIONS = 'REMOVE_ANIMATIONS';
export const SET_LIGHTS_OFF = 'SET_LIGHTS_OFF';
export const RESET_CONTRAST = 'RESET_CONTRAST';
export const RESET_SETTINGS = 'RESET_SETTINGS';

export const UPSERT_VISITED_PLACES = 'UPSERT_VISITED_PLACES';
export const RESET_VISITED_PLACES = 'RESET_VISITED_PLACES';

export const SEARCH_SET_TEXT = 'SEARCH_TEXT';
export const SEARCH_SET_TYPE = 'SEARCH_TYPE';
export const SEARCH_SET_DISABILITY = 'SEARCH_DISABILITY';
export const SEARCH_CLEAN = 'SEARCH_CLEAN';

export const MAP_SET_ZOOM = 'MAP_SET_ZOOM';
export const MAP_RESET_CENTER = 'MAP_RESET_CENTER';
export const MAP_SHOWING_PINS = 'MAP_SHOWING_PINS';
