export const LOGIN = '/auth/login';
export const LOGIN_BY_PROVIDER = '/auth/login-by-provider';
export const SIGNUP = '/auth/register';
export const CHECK_EMAIL = '/auth/check-email';
export const RECOVER_PASSWORD = '/auth/password-recover';
export const CHANGE_PASSWORD = '/auth/reset-password';
export const GET_USER = '/profile';
export const UPDATE_USER = '/profile/update';
export const PLACE_EVALUTATIONS = '/place-evaluations';
export const MY_PLACE_EVALUTATIONS = '/auth/place-evaluations';
export const PLACES = '/places';
export const PLACES_DELETE = '/places/delete';
export const PLACES_RADIUS = '/places/radius';
export const QUESTIONS = '/place-rate-settings';
